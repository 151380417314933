import { Link } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const content = `A full-service hair salon featuring world-class, satisfaction-motivated stylists that strive to create a relaxing, soul-quenching experience. Enjoy complimentary coffee and snacks in our cozy salon located at 8923 W. Overland Rd in Boise, ID.`;

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="home-1"></div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="home-content">
            <h3 style={{ textAlign: "center" }}>SOUL.</h3>
            <h3 style={{ textAlign: "center" }}>a beauty lounge</h3>
            <div className="home-copy">
              <ul>
                <li>Wellness enthusiasts.</li>
                <li>Self care advocates.</li>
                <li>Purposed to serve.</li>
              </ul>
              <p style={{ textAlign: "center" }}>{content}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row buttons">
        <div className="col" style={{ textAlign: "center" }}>
          <a
            href="https://squareup.com/appointments/book/3bb61l22xo7y44/LABND9DGT2DBC/services"
            className="btn btn-secondary cta-button"
          >
            BOOK US
          </a>
        </div>
      </div>

      <div className="home-2"></div>
    </>
  );
};
export default Home;
