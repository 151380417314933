import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home";
import Logo from "./components/Logo/Logo";
import Navbar from "./components/Navbar";
import NotFound from "./components/NotFound/NotFound";
import Products from "./components/Products/Products";
import SOULmates from "./components/SOULmates/SOULmates";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Logo />
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/products"} element={<Products />} />
          <Route path="/SOULmates" element={<SOULmates />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
