import "./Navbar.css";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-dark bg-transparent">
        <div className="container-fluid">
          <hr />
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-end">
              <li className="nav-item">
                <a className="nav-link" href="/" aria-current="page">
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="/products">
                  Products
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/SOULmates">
                  SOULmates
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link">SOUL GIVES</a>
              </li>               */}
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
