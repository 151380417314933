import "./Products.css";

const Products = () => {
  return (
    <>
      <div className="products-1"></div>

      <div className="product-content">Buy our products! Yay!</div>
      <div className="products-2"></div>
      <div className="product-content">Sooo many products</div>
      <div className="products-3"></div>
      <div className="product-content">ya</div>
    </>
  );
};

export default Products;
