import "./SOULmates.css";
import { useMemo } from "react";
import { CiInstagram } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";

const SOULmates = () => {
  const mates = useMemo(() => {
    return [
      {
        name: "Ke'Shana Beasley",
        imageLink: "",
        title: "Salon Owner | Hair Stylist",
        instagramURL: "https://www.instagram.com/keshana.does.hair/",
        facebookURL: "https://www.facebook.com/soulabeautylounge",
        bookingLink:
          "https://squareup.com/appointments/book/3bb61l22xo7y44/LABND9DGT2DBC/start",
        bio: "",
      },
      {
        name: "Ashley Armstrong",
        imageLink: "",
        title: "Hair Stylist",
        instagramURL: "https://www.instagram.com/beautybyashley_a/",
        bookingLink: "https://ashley-armstrong.square.site/",
        bio: "",
      },
      {
        name: `Maddison 'Maddy' Heimback`,
        imageLink: "",
        title: "Master Lash Artist | Esthetician",
        instagramURL: "https://www.instagram.com/darling.esthetics/",
        bookingLink: "https://www.vagaro.com/darlingestheticsllc",
        bio: `Maddison is a licensed esthetician and 5x certified lash artist. Maddison offers luxury facials, eyelash extensions, brow laminations and tinting, lash lifts & tints, and facial waxing. She has a big family and amazing partner, Josiah. 
        
        Maddison loves reading books, training Rosie (her Bernedoodle puppy), working out, spending time with family, and trying new things! 
        
        Maddison is also a big time fan of true crime: 
        - podcasts,
        - documentaries,
        - books,
        - you name it!
        
        Maddison's favorite service is the Darling Signature Facial. 
        
        She can't wait to have you in her chair and to be a part of SOUL!`,
      },
      {
        name: "Julie Gray",
        imageLink: "",
        title: "Hair Stylist",
        instagramURL: "https://www.instagram.com/juliegrayhair/",
        bookingPhone: "208.447.6594",
        bookingLink: "",
        bio: "",
      },
      {
        name: `Jamie 'Pepper' Pepper`,
        imageLink: "",
        title: "Hair Stylist",
        instagramURL: "https://www.instagram.com/jpepbeauty/",
        bookingLink:
          "https://squareup.com/appointments/book/3bb61l22xo7y44/LABND9DGT2DBC/start",
        bio: "",
      },
      {
        name: "Sidney Chavez",
        imageLink: "",
        title: "Hair Stylist",
        instagramURL: "https://www.instagram.com/hairbysidney/",
        facebookURL: "https://www.facebook.com/HairbySidney",
        bookingLink:
          "https://squareup.com/appointments/book/3iv9e25l6d7ul8/100V9JYKJT8BT/start",
        bio: `My name is Sidney & I’m an Ida-home native born & raised! I’ve always been passionate about hair from a young age I knew “this is it!”. I still have my kindergarten time capsule that says “I want to do hair” when I grow up! I’ve been doing hair since Jr. high wether it was getting friends ready for school dances & pictures or cutting & coloring my own along with family and friends! I started my hair journey professionally at Toni&Guy in 2012! I pour my heart & SOUL into every client I get the pleasure of meeting!

        I want everyone who sits in my chair to feel loved, accepted & most of all comfortable! I am a proud member of the LQBTQIA+ community & I’d love to welcome other members in my chair with open arms & an open heart in a safe & loving environment! 
        
        In my spare time I enjoy spending time with my fiancé, Chiweenie, family & friends! I love staying active paddle boarding, swimming, camping & supporting local trying new foods! I also love traveling!! You can find me along the Oregon coast any opportunity I get! I also love spending time at home cuddled up with my fiancé & doggy watching some Bachelorette or true crime documentaries to name a few! 

        I look forward to meeting you all! `,
      },
    ];
  }, []);

  const soulMates = mates.map((soulMate) => (
    <div className="row mate-row">
      <div className="card mb-3 mate-card" key={soulMate.name}>
        {soulMate.imageLink && (
          <img
            className="card-img-top"
            src={soulMate.imageLink || undefined}
            alt={`${soulMate.name} at SOUL a beauty lounge`}
          />
        )}
        <div className="card-body">
          <h3 className="card-title">{soulMate.name}</h3>
          <h6 className="card-subtitle mb-2 text-muted">{soulMate.title}</h6>
          <div className="row">
            <div className="col-1 justify-content-start">
              <a target="_blank" rel="noreferrer" href={soulMate.instagramURL}>
                <CiInstagram className="SOULmates-social-icon" />
              </a>
            </div>
            {soulMate.facebookURL && (
              <div className="col-1 justify-content-start">
                <a target="_blank" rel="noreferrer" href={soulMate.facebookURL}>
                  <CiFacebook className="SOULmates-social-icon" />
                </a>
              </div>
            )}
          </div>
          {soulMate.bio && (
            <p className="card-text stylist-bio">{soulMate.bio}</p>
          )}
          {/* {soulMate.bookingLink ? (
            <a
              target="_blank"
              className="book-button"
              href={soulMate.bookingLink}
              rel="noreferrer"
            >
              Book Now
            </a>
          ) : (
            <a className="book-button" href={`tel:${soulMate.bookingPhone}`}>
              Call or Text
            </a>
          )} */}
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container-fluid">
      <div>{soulMates}</div>
    </div>
  );
};
export default SOULmates;
