import "./Contact.css";

const Contact = () => {
  return (
    <div className="container-fluid hiring">
      <div className="row">
        <h3 className="col were-hiring">We're hiring</h3>
      </div>
      <div className="row">
        <div className="col were-hiring">
          <p className="">
            {`Please be sure to include your professional
              pages (if applicable)!`}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col were-hiring">
          <p>admin [at] soulbeautylounge [dot] com</p>
        </div>
      </div>
    </div>
  );
};
export default Contact;
