import "./Footer.css";
import { CiInstagram } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";

const Footer = () => {
  const address = "8923 W. Overland Rd";
  const address2 = "Boise, ID 83709";
  const phone = "208.639.2606";

  return (
    <>
      <div className="container-fluid footer">
        <div className="row footer-address-row">
          <div className="col-lg">
            <h3 className="footer-content">{address}</h3>
          </div>
        </div>
        <div className="row footer-address-row">
          <div className="col-lg">
            <h3 className="footer-content">{address2}</h3>
          </div>
        </div>
        <div className="row footer-address-row">
          <div className="col-4 footer-content">
            <h3>
              <a href={`tel:${phone}`}>{phone}</a>
            </h3>
          </div>
        </div>
        <div className="row footer-social-row">
          <div className="col-4">
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/soul.a.beauty.lounge/"
            >
              <CiInstagram />
            </a>
          </div>
          <div className="col-4">
            <a
              className="social-icon"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/soulabeautylounge"
            >
              <CiFacebook />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
