import logo from "../../assets/Salon-Assests/logo2.jpeg";

const Logo = () => {
  return (
    <div className="row logo-row">
      <img
        src={logo}
        className="img-fluid logo-img"
        alt="SOUL a beauty lounge"
      ></img>
    </div>
  );
};

export default Logo;
